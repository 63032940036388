import React from "react";
import { graphql, Link } from "gatsby";
import classnames from "classnames";
import moment from "moment";

import Moment from "react-moment";
import Layout from "../components/layout";
import RichText from "../components/richtext";
import PageTitle from "../components/pagetitle";

const MonthlyGridTemplate = ({ data }) => {
  const { grid_title, description, body } = data.prismicMonthlyGrid.data;
  return (
    <Layout>
      <div className="max-w-6xl mx-auto px-4">
        <PageTitle text={grid_title.text} />
        <RichText html={description.html} />
        <table className="w-full">
          <thead>
            <td className="border"></td>
            {moment.monthsShort().map((month, index) => (
              <td className="border text-center" key={index}>
                {month}
              </td>
            ))}
          </thead>
          <tbody>
            {body.map(({ primary, items }, index) => (
              <tr
                className={classnames({
                  "bg-gray-200": index % 2 === 0 || index === 0
                })}
              >
                <td className="p-2 border">{primary.peak_name}</td>
                {moment.monthsShort().map((month, index) => (
                  <td className="border text-xs text-center">
                    {items.map(({ date_hiked, blog_post }) => {
                      if (moment(date_hiked).month() === index) {
                        const formattedDate = moment(date_hiked).format("YYYY");
                        if (!!blog_post.document) {
                          const dateArr = blog_post.document.data.date.split(
                            "-"
                          );
                          const path = `/blog/${dateArr[0]}/${dateArr[1]}/${dateArr[2]}/${blog_post.uid}/`;
                          return (
                            <Link
                              className="underline hover:no-underline"
                              to={path}
                            >
                              {formattedDate}
                            </Link>
                          );
                        } else {
                          return <span className="pr-1">{formattedDate}</span>;
                        }
                      }
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default MonthlyGridTemplate;

export const query = graphql`
  query MonthlyGridPageByUid($uid: String!) {
    prismicMonthlyGrid(uid: { eq: $uid }) {
      data {
        grid_title {
          text
        }
        description {
          html
        }
        body {
          ... on PrismicMonthlyGridBodyPeakData {
            primary {
              peak_name
              elevation
            }
            items {
              date_hiked
              blog_post {
                uid
                document {
                  ... on PrismicBlogPost {
                    data {
                      date
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
